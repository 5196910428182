import React from "react";
import "./home.css";

import { PublicMenu } from "../../../components/Menu/PublicMenu";

export const Home = () => {
  return (
    <>
      <div className="showcaseContainer">
        <div className="showcaseTop">
          <div>
            <div className="showcaseVision">Showcase your work</div>
            <div className="showcaseVision">Collaborate with other talents</div>
            <div className="showcaseVision">Monetize your work</div>
          </div>
          <PublicMenu />
        </div>
        <div className="showcaseHero">
          <div className="showcaseTitle">Sensitive Surface</div>
          <div className="showcaseCaption">Epicenter of creativity</div>
          <div className="showcaseButtonGroup">
            <button className="showcaseButton">Join Now</button>
            <button className="showcaseButton">Login</button>
          </div>
        </div>
        <div className="footer">
          <div>
            <div className="showcaseVisionMobile">Showcase your work</div>
            <div className="showcaseVisionMobile">
              Collaborate with other talents
            </div>
            <div className="showcaseVisionMobile">Monetize your work</div>
          </div>
          <hr />
          <div className="footerTop">Find talents</div>
          <div className="footerTalents">
            <div>Photographers</div>
            <div>Models</div>
            <div>Designers</div>
            <div>Makeup Artists</div>
            <div>Hair stylists</div>
            <div>Actors</div>
            <div>Influencers</div>
          </div>
        </div>
      </div>
      {/* <div>
        <div className="showcaseTitle">Trending</div>
        <div className="showcaseCaption">Most popular talents</div>
      </div> */}
    </>
  );
};
