import React from "react";
import { Link, useLocation } from "react-router-dom";

type Props = {};

export const PublicMenu = ({}: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const homeLinkStyle = {
    fontWeight: pathname === "/" ? "bold" : "normal",
  };
  return (
    <div className="showcaseMenu">
      <div className="showcaseMenuItem">
        <Link to="/" style={homeLinkStyle}>
          Home
        </Link>
      </div>
      <div className="showcaseMenuItem">
        <Link to="/explore">Explore</Link>
      </div>
      <div className="showcaseMenuItem">
        <Link to="/about">About</Link>
      </div>
      <div className="showcaseMenuItem">
        <Link to="/terms">Terms of service</Link>
      </div>
      <div className="showcaseMenuItem">
        <Link to="/privacy">Privacy policy</Link>
      </div>
      <div className="showcaseMenuItem">
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  );
};
