import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const uri = process.env.REACT_APP_API_URL;
let deviceUUID = localStorage.getItem("deviceUUID");
if (!deviceUUID) {
  deviceUUID = crypto.randomUUID();
  localStorage.setItem("deviceUUID", deviceUUID);
}

const httpLink = createHttpLink({
  uri,
});

export const replaceLinkChainOnClient = (
  client: ApolloClient<object>,
  token: string
) => {
  const link = setContext(async (operation, prevContext) => {
    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        Authorization: `Bearer ${token}`,
        "x-device-uuid": deviceUUID,
      },
    };
  });
  client.setLink(link.concat(httpLink));
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-device-uuid": deviceUUID,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem("token"));

export default client;
