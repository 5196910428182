import React from "react";
import { Routes, Route } from "react-router-dom";
import { Root, Home, About, Contact, ErrorPage } from "../views";
import { Home as PublicHome } from "../views/public/home/Home";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../apollo/client";

// export const AuthRoutes = () => {
//   const isLoggedIn = useReactiveVar(isLoggedInVar);
//   if (isLoggedIn) {
//     return <Route path="/dashboard" element={<DashBoard />} />;
//   } else {
//     return <Route path="/login" element={<Login />} />;
//   }
// };

export const Router = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  return (
    <Routes>
      {/* <Route path="/" element={<PublicHome />} errorElement={<ErrorPage />}> */}
      <Route path="/" element={<PublicHome />} />

      {/* {isLoggedIn ? (
          <Route path="/dashboard" element={<DashBoard />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )} */}
      {/* </Route> */}
      <Route path="/explore" element={<PublicHome />} />
      <Route path="/about" element={<PublicHome />} />
      <Route path="/terms" element={<PublicHome />} />
      <Route path="/privacy" element={<PublicHome />} />
      <Route path="/contact" element={<PublicHome />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
